$mainBlue: #3b71c9;
$headerBorder: 1px solid rgba(255, 255, 255, 0.3);
.header {
  left: 0;
  right: 0;
  top: 0;
  height: 60px;
  position: fixed;
  background: $mainBlue;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
  display: flex;
  z-index: 1001;

  a {
    text-decoration: none;
    font-size: large;
    color: white;
  }
}
body {
  font-family: 'Lato', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  //height: 100vh;
}
.icon-upload {
  width: 15px;
  height: 15px;
  background-image: url('../Images/upload.svg');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-download {
  width: 18px;
  height: 18px;
  background-image: url('../Images/download.svg');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-select {
  width: 20px;
  height: 20px;
  background-image: url('../Images/select.svg');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-error {
  width: 20px;
  height: 20px;
  background-image: url('../Images/error.svg');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 10px;
}

.logo {
  width: 120px;
  height: 30px;
  background-image: url('../Images/logo.svg');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 20px;
}

.frame {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

#root {
  height: 100%;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-self: center;
}

.App {
  margin-left: auto;
  margin-right: auto;
  width: 80vw;
  padding: 30px;
  margin: auto;
  position: relative;
  padding-top: 90px;
}

.label {
  font-size: 12px;
  color: #6099f6;
  padding-bottom: 10px;
}

.value {
  position: relative;
  &:hover {
    background: rgba(0, 0, 0, 0.02);
    cursor: pointer;
  }
  input {
    font-size: 12px;
    background: none;
    color: #343435;
    font-weight: bolder;
    width: 100%;
    border-radius: 6px;
    padding: 15px;
    padding-top: 8px;
    padding-bottom: 9px;
    border: 0;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 0.5;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      opacity: 0.5;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      opacity: 0.5;
    }
  }
}

.login-btn {
  width: 100%;
  height: 30px;
}

.button-container {
  font-weight: normal;
  text-align: center;
  padding: 6px;
  display: flex;
  align-items: center;
  .label {
    color: #7e7d7d !important;
    position: unset;
  }

  &:hover {
    //background: #fff;
    cursor: pointer;
    // .label {
    //   //color: #fff !important;
    // }
    opacity: 0.8;
  }
}

.select-container {
  position: relative;
  .options {
    z-index: 10;
    margin-top: 5px;
    max-height: 300px;
    overflow-y: auto;
  }
  .option {
    padding: 6px;
    &:hover {
      cursor: pointer;
      background: rgba(0, 0, 0, 0.03);
    }
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 12px;
  }
  .value {
    color: #343435;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    padding: 8px;
    min-height: 32px;

    .icon-select {
      position: absolute;
      right: 0;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

.controls {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .select-container {
    width: 80px;
    margin-left: 20px;
  }
  .input-container {
    width: calc(100% - 100px);
  }
}

.options {
  position: absolute;
  width: 100%;
}

.selectFile-container {
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 30px;
  position: relative;
  .hiddenText {
    position: absolute;
    font-size: 12px;
    color: #fff;
    text-align: right;
    right: 10px;
  }
}
.custom-file-input {
  z-index: 10;
  font-weight: normal;
  border-radius: 6px;
  color: transparent;
  max-width: 80px;
  padding-top: 3px;
  padding-bottom: 3px;
  opacity: 0;
  cursor: pointer;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
  opacity: 0;
}
.custom-file-input::before {
  font-weight: bolder;
  text-align: center;
  padding: 6px;

  color: #fff !important;

  &:hover {
    background: #5bc3fe;
    cursor: pointer;
    color: #fff;
  }
  font-size: 12px;

  content: 'Upload Excel';
  display: inline-block;
  background: none;
  border: none;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  font-weight: lighter;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  //text-shadow: 1px 1px #fff;
  font-family: 'Lato', sans-serif;

  font-size: 12px;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #3b71c9, #3b71c9);
}

.progress-container {
  height: 10px;
  width: calc(100% - 100px);
  margin-right: 20px;

  background: #f7f7f9;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.progress-value {
  background: linear-gradient(225deg, #28c4f4 0%, #2ec892 100%);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;

  border-radius: 5px;
  height: 100%;
  position: absolute;
}

.file-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 30px;
  .label {
    position: absolute;
    left: 0;
    top: -15px;
  }
}

.table-options {
  display: flex;
  justify-content: flex-end;
  .download-estimates {
    margin-right: 15px;
    .button-container {
      padding-top: 8px;
      padding-bottom: 8px;
      background: #2ec98f;
    }
    .label {
      padding-bottom: 0px;
      color: #fff !important;
    }
  }
}

.disabled {
  opacity: 0.5;
  &:hover {
    background: none !important;
    .label {
      color: #28c4f4 !important;
    }
  }
}

.error-container {
  display: flex;
  color: #e0a390;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 10px;
  background: #f7f7f9;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
}

.header {
  justify-content: space-between;
  display: flex;
  align-items: center;
  .logo {
    margin-left: 30px;
    margin-bottom: 0;
  }
  .config {
    display: flex;
    align-items: center;

    .input-container {
      margin-right: 30px;
      width: 40vw;
      height: 30px;
      border-radius: 5px;
      &:hover {
        background: rgba(255, 255, 255, 0.1);
        cursor: pointer;
      }
      .label {
        display: flex;
        align-items: center;
        padding: 10px;
        color: rgba(255, 255, 255, 0.5);
        justify-content: center;
      }
      .value {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: none;
        border: 0;
        input {
          background: none;
          width: 100%;
          color: #fff;
        }
      }

      display: flex;
      background: none;
      border: $headerBorder;
    }
    .select-container {
      &:hover {
        background: rgba(255, 255, 255, 0.1);
        cursor: pointer;
      }

      background: none;
      display: flex;
      border: $headerBorder;
      margin-right: 30px;
      width: 200px;
      height: 30px;
      border-radius: 5px;
      .options {
        margin-top: 50px;
      }

      .label {
        display: flex;
        align-items: center;
        padding: 10px;
        color: rgba(255, 255, 255, 0.5);
        justify-content: center;
        width: 100px;
      }
      .value {
        .icon-select {
          border-left: 1px solid rgba(255, 255, 255, 0.2);
        }
        color: #fff;
        width: calc(100% - 80px);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: none;
        border: 0;
        input {
          background: none;
          width: 100%;
          color: #fff;
        }
      }
    }
    .button-container {
      margin-right: 30px;
      background: none;
      color: rgba(255, 255, 255, 0.5);
      justify-content: center;
      align-items: center;
      display: flex;
      height: 33px;
      width: 120px;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
        cursor: pointer;
      }
      .label {
        font-weight: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;

        padding: 0;
      }
      display: flex;
      .label {
        display: flex;
        color: #fff !important;
        margin-left: 10px;
        align-items: center;
      }
      border: $headerBorder;
    }
  }
}

.sectionContainer {
  display: flex;
  align-items: center;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 30px;
}

.flex-break {
  flex-basis: 100%;
  height: 0;
}

.formContainer {
  background: #ffffff;
  border: 1px solid rgba(59, 113, 201, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 0px 25px rgba(59, 113, 201, 0.1);
  border-radius: 8px;
  padding: 20px;
  span {
    font-size: small;
    color: #3b71c9;
    text-decoration: underline;
  }
}
.addForm {
  display: flex;
  flex-wrap: wrap;

  align-items: center;

  .form-error {
    color: rgba(250, 62, 62, 0.671);
    margin-top: 5px;
    margin-bottom: 0;
  }

  .btn-add {
    padding-top: 20px;
    .button-container {
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
      border: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      background: #2ec98f !important;
      font-weight: bold;
      .label {
        padding: 0;
        color: #fff !important;
      }
    }
  }
}

.table {
  background: #ffffff;
  border: 1px solid rgba(59, 113, 201, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 0px 25px rgba(59, 113, 201, 0.1);
  border-radius: 8px;
  padding: 20px;

  label {
    float: right;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  th {
    text-align: left;
    font-size: 12px;
    color: #6099f6;
    padding-bottom: 10px;
    font-weight: normal;
  }
  .value {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .titles th {
    border-bottom: 1px solid #3b71c9;
  }

  .data td {
    border-bottom: 1px solid rgba(106, 159, 247, 0.5);
  }

  tr {
    font-size: 14px;
  }
}

.progressBox {
  .progress-container {
    width: 100%;
    height: 8px;
  }

  display: flex;
  background: #ffffff;
  border: 1px solid rgba(59, 113, 201, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 0px 25px rgba(59, 113, 201, 0.1);
  border-radius: 8px;
  padding: 20px;
  position: fixed;
  bottom: 20px;
  left: 20%;
  right: 20%;

  align-items: center;
}

.bold {
  font-weight: bold;
  font-size: 14px !important;
}

.value {
  font-size: 12px;
}

.bold-green {
  font-weight: bold;
  color: #2ec98f;
  font-size: 14px !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.modal {
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0px 0px 25px rgb(59 113 201 / 10%);
  width: 720px;
  display: flex;
  flex-direction: column;

  h2,
  p,
  ol {
    margin-top: 0;
    margin-bottom: 10px;
    li {
      margin-bottom: 0.25em;
    }
  }

  tr {
    border: 1px solid;
  }

  ol {
    span {
      color: lighten(#3b71c9, 5%);
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-header,
  &-body,
  &-footer {
    padding: 5px;
  }

  &-footer {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;

    .button-container .label {
      color: #7e7d7d !important;
    }

    .selectFile-container {
      margin-right: 30px;
      background-color: #3b71c9;
    }
  }

  &-header {
    border-bottom: 1px solid #7e7d7d;
  }

  .btn {
    background-color: #fff;
    font-family: inherit;
    border: 1px solid #7e7d7d;
    border-radius: 6px;
    padding: 10px;
    cursor: pointer;

    &:hover {
      border-color: transparent;
    }
  }
}

.place-center {
  place-items: center;
  place-content: center;
  display: flex;
}

.full {
  height: 100%;
  width: 100%;
}
html,
body,
#root {
  height: 100vh;
  width: 100vw;
}
.login-container {
  background-color: $mainBlue;
  height: 400px;
  width: 400px;
  border-radius: 10px;
  padding: 20px;

  flex-direction: column;

  .logo {
    height: 60px;
    width: 240px;
  }

  a {
    text-decoration: none;
    color: unset;
  }
  .button-container {
    font-size: large;
    text-transform: uppercase;
    width: 50%;
    justify-content: center;
  }
}

.user-info {
  color: white;
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  p {
    margin: unset;
    font-size: 15px;
  }
}

.term-n-conditions {
  z-index: 1001;
  .overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 1000;
    top: 0;
    left: 0;
  }
  position: inherit;
  .modal-overlay {
    display: flex;
    z-index: 1001;
  }
  .modal {
    height: 60%;
  }
  .modal-body {
    overflow-y: scroll;
    height: 100%;
  }
  .checkbox {
    padding: 10px 0;
  }
  .btn {
    background-color: #3b71c9 !important;
    color: white;
  }
  .disabled {
    background-color: #5d8fe2 !important;
  }
  .lst-kix_4acad0y3aqq3-4 > li:before {
    content: '\0025cb  ';
  }
  .lst-kix_4acad0y3aqq3-5 > li:before {
    content: '\0025a0  ';
  }
  .lst-kix_4acad0y3aqq3-2 > li:before {
    content: '\0025a0  ';
  }
  .lst-kix_4acad0y3aqq3-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_4acad0y3aqq3-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_4acad0y3aqq3-7 > li:before {
    content: '\0025cb  ';
  }
  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  ul.lst-kix_4acad0y3aqq3-1 {
    list-style-type: none;
  }
  ul.lst-kix_4acad0y3aqq3-0 {
    list-style-type: none;
  }
  ul.lst-kix_4acad0y3aqq3-3 {
    list-style-type: none;
  }
  ul.lst-kix_4acad0y3aqq3-2 {
    list-style-type: none;
  }
  ul.lst-kix_4acad0y3aqq3-5 {
    list-style-type: none;
  }
  ul.lst-kix_4acad0y3aqq3-4 {
    list-style-type: none;
  }
  ul.lst-kix_4acad0y3aqq3-7 {
    list-style-type: none;
  }
  .lst-kix_4acad0y3aqq3-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_4acad0y3aqq3-1 > li:before {
    content: '\0025cb  ';
  }
  ul.lst-kix_4acad0y3aqq3-6 {
    list-style-type: none;
  }
  .lst-kix_4acad0y3aqq3-8 > li:before {
    content: '\0025a0  ';
  }
  ul.lst-kix_4acad0y3aqq3-8 {
    list-style-type: none;
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c3 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 12pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c6 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }
  .c0 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 12pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c1 {
    color: #212529;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10.5pt;
    font-family: 'Arial';
    font-style: normal;
  }
  .c2 {
    color: #212529;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10.5pt;
    font-family: 'Arial';
    font-style: italic;
  }
  .c5 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Arial';
    font-style: normal;
  }
  .c7 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 30pt 30pt 30pt 30pt;
  }
  .c4 {
    padding: 0;
    margin: 0;
  }
  .c8 {
    color: #212529;
    font-size: 10.5pt;
  }
  .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
    color: #000000;
    font-size: 11pt;
    font-family: 'Arial';
  }
  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: 'Arial';
  }
  h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
.upload-btn {
  position: absolute;
  right: 180px;
}

.tab-container {
  width: 180px;
  display: flex;
  gap: 10px;
  .tab {
    font-size: 13px !important;
    color: hsla(0, 0%, 100%, 0.5);
    opacity: 1;
    border-bottom: 2px solid #3b71c9;
    font-weight: 600;
    padding-bottom: 0.35em;
  }
  .selected {
    color: #fff;
    border-bottom: 2px solid #fff;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #3b71c9;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.signup-buildium {
  height: 100%;
  .login-container {
    height: 150px;
  }
  .full {
    flex-direction: column;
  }
  .content {
    padding: 20px;
    text-align: center;
    .main {
      height: 300px;
      display: flex;
      place-content: center;
      place-items: center;
    }
    .title {
      color: #3b71c9;
      font-size: xx-large;
      height: 50px;
    }
    .high {
      font-size: large;
      color: #434343;
    }
    .adjust {
      height: 20px;
    }
    .low {
      font-size: large;
      color: #434343;
    }
  }
  .help-container {
    display: flex;
    place-content: center;
    .help {
      position: absolute;
      padding: 20px;
      bottom: 10px;
      text-align: center;
    }
  }
}

.settings {
  .settings-label {
    font-size: 15px;
    font-weight: bold;
  }
  .settings-desc {
    font-size: small;
    font-weight: light;
  }
  tr {
    padding-bottom: 20px;
  }
  td {
    vertical-align: unset;
    padding: 10px;
  }

  /* CSS */
  .button-3 {
    appearance: none;
    background-color: #2ea44f;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, 'Segoe UI', Helvetica, Arial,
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 6px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
  }

  .button-3:focus:not(:focus-visible):not(.focus-visible) {
    box-shadow: none;
    outline: none;
  }

  .button-3:hover {
    background-color: #2c974b;
  }

  .button-3:focus {
    box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
    outline: none;
  }

  .button-3:disabled {
    background-color: #94d3a2;
    border-color: rgba(27, 31, 35, 0.1);
    color: rgba(255, 255, 255, 0.8);
    cursor: default;
  }

  .button-3:active {
    background-color: #298e46;
    box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
  }
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    255,
    255,
    255,
    0.6
  ); /* Black background with opacity */
  bottom: 0;
  z-index: 3000; /* Specify a stack order in case you're using a different order for other elements */
}
