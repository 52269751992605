
.modal-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 3000;
  width: 100%;
  height: 100%;
  .question {
    display:flex;
    justify-content:center;
    
  }
  .modal {
    max-width:300px;
    background: #fff;
    padding: 35px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 2px 1px 24px -9px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 2px 1px 24px -9px rgba(0, 0, 0, 0.3);
    box-shadow: 2px 1px 24px -9px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    font-size: 13px;
    .answers {
      display: flex;
      justify-content: center;
      margin-top: 25px;
      cursor: pointer;
      font-weight: bold;
      font-size: 12px;
      .answer-yes {
        background: #5bc3fe;
        color: #fff;
        padding: 5px;
        padding-top:8px;
        padding-bottom:8px;
        border-radius: 5px;
        width: 50px;
        text-align: center;
        margin-right: 10px;
        &:hover {
          opacity: 0.8;
        }
      }
      .answer-no {
        display:flex;
        align-items:center;
        justify-content:center;
        background: rgb(230, 230, 230);
        color: #333;
        padding: 5px;
        border-radius: 5px;
        width: 50px;
        text-align: center;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
