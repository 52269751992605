.gridContainer {
  display: grid;
  grid-template-areas: 'filters graph';
  grid-template-columns: 300px auto;
  gap: 20px;
}
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  height: 100%;
}
.indices-table {
  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }
}
.graph {
  padding: 20px;
}
/* On mouse-over, add a deeper shadow
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
} */

/* Add some padding inside the card container */
.container {
  padding: 2px 16px;
}

.legend {
  line-height: 18px;
  color: #555;
  margin-right: 35px !important;
}
.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

.leaflet-control-attribution {
  display: none !important;
}
